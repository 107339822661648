import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'

declare module 'vue-router' {
  interface RouteMeta {
    title?: string // titleタグ用
    hero?: { title: string; titleEn: string; description?: string }
    breadcrumb?: { label: string; path: string }[]
  }
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      const mediaQueryList = window.matchMedia('(min-width: 601px)')
      return { el: to.hash, top: mediaQueryList.matches ? 80 : 60 }
    } else {
      return { left: 0, top: 0 }
    }
  },
  routes,
})

export default router

<template>
  <a class="fixed_shop_banner" href="https://coffee-syphon.shop-pro.jp/" target="_blank">
    <img src="@/assets/img/top/banner.jpg" alt="オンラインショップ" />
  </a>
</template>

<style scoped lang="scss">
.fixed_shop_banner {
  display: none;
  position: fixed;
  inset: auto 24px 0 auto;
  z-index: 10;
  @include desktop() {
    display: block;
  }
  img {
    vertical-align: middle;
  }
}
</style>

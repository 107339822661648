import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'TopLayout',
    component: () => import('../layouts/top-layout.vue'),
    children: [{ path: '', name: 'Index', component: () => import('@/pages/index') }],
    meta: {
      title: '', // titleタグ用
    },
  },
  {
    path: '/',
    name: 'CommonLayout',
    component: () => import('../layouts/common-layout/'),
    children: [
      {
        path: 'development/',
        name: 'Development',
        component: () => import('@/pages/development.vue'),
        meta: {
          title: '器具の開発の歴史', // titleタグ用
          hero: {
            title: '器具の開発の歴史',
            titleEn: 'DEVELOPMENT',
          },
          breadcrumb: [{ label: 'トップ', path: '/' }],
        },
      },
      {
        path: 'manual/',
        name: 'ManualIndex',
        component: () => import('@/pages/manual/index.vue'),
        meta: {
          title: '珈琲の美味しい淹れ方', // titleタグ用
          hero: {
            title: '珈琲の美味しい淹れ方',
            titleEn: 'HOW TO DRIP',
          },
          breadcrumb: [{ label: 'トップ', path: '/' }],
        },
      },
      {
        path: 'coffee_syphon/',
        name: 'coffeeSyphon',
        component: () => import('@/pages/coffee-syphon/index.vue'),
        meta: {
          title: 'コーヒーサイフォン',
          hero: {
            title: 'コーヒーサイフォン',
            titleEn: 'COFFEE SYPHON',
            description: 'コーヒーの持ち味を\n素直に抽出する、\n珈琲サイフオン',
          },
          breadcrumb: [{ label: 'トップ', path: '/' }],
        },
      },
      {
        path: 'coffee_syphon/:productId',
        name: 'coffeeSyphonDetail',
        component: () => import('@/pages/coffee-syphon/detail.vue'),
        props: ({ params }) => ({
          categoryName: 'コーヒーサイフォン',
          categorySlug: 'coffee_syphon',
          productId: params.productId,
        }),
        meta: {
          title: 'コーヒーサイフォン',
          breadcrumb: [
            { label: 'トップ', path: '/' },
            { label: 'コーヒーサイフォン', path: '/coffee_syphon/' },
          ],
        },
      },
      {
        path: 'meimon_filter/',
        name: 'meimonFilter',
        component: () => import('@/pages/meimon-filter/index.vue'),
        meta: {
          title: '名門フィルター',
          hero: {
            title: '名門フィルター',
            titleEn: 'MEIMON FILTER',
            description: 'コーヒーの風味と旨味を\n最大限に引き出し、\n雑味のないクリアな味わい',
          },
          breadcrumb: [{ label: 'トップ', path: '/' }],
        },
      },
      {
        path: 'meimon_filter/:productId',
        name: 'meimonFilterDetail',
        component: () => import('@/pages/meimon-filter/detail.vue'),
        props: ({ params }) => ({
          categoryName: '名門フィルター',
          categorySlug: 'meimon_filter',
          productId: params.productId,
        }),
        meta: {
          title: '名門フィルター',
          breadcrumb: [
            { label: 'トップ', path: '/' },
            { label: '名門フィルター', path: '/meimon_filter/' },
          ],
        },
      },
      {
        path: 'coffee_server/',
        name: 'coffeeServer',
        component: () => import('@/pages/coffee-server/index.vue'),
        meta: {
          title: 'コーヒーサーバー',
          hero: {
            title: 'コーヒーサーバー',
            titleEn: 'COFFEE SERVER',
            description: '手間を惜しまず、\n確実に風味を引き出す\n本格派のあなたに',
          },
          breadcrumb: [{ label: 'トップ', path: '/' }],
        },
      },
      {
        path: 'coffee_server/:productId',
        name: 'coffeeServerDetail',
        component: () => import('@/pages/coffee-server/detail.vue'),
        props: ({ params }) => ({
          categoryName: 'コーヒーサーバー',
          categorySlug: 'coffee_server',
          productId: params.productId,
        }),
        meta: {
          title: 'コーヒーサーバー',
          breadcrumb: [
            { label: 'トップ', path: '/' },
            { label: 'コーヒーサーバー', path: '/coffee_server/' },
          ],
        },
      },
      /*
      {
        path: 'roasted_coffee/',
        name: 'roastedCoffee',
        component: () => import('@/pages/product/category.vue'),
        props: { categoryName: 'コーヒー豆', categorySlug: 'roasted_coffee' },
        meta: {
          title: 'コーヒー豆',
          hero: {
            title: 'コーヒー豆',
            titleEn: 'ROASTED COFFEE',
            description: '独自の焙煎理論\n熟達の焙煎技術\n引き継がれている味',
          },
          breadcrumb: [{ label: 'トップ', path: '/' }],
        },
      },
      {
        path: 'roasted_coffee/:productId',
        name: 'roastedCoffeeDetail',
        component: () => import('@/pages/product/detail.vue'),
        meta: {
          title: 'コーヒー豆',
          breadcrumb: [
            { label: 'トップ', path: '/' },
            { label: 'コーヒー豆', path: '/roasted_coffee/' },
          ],
        },
      },
      {
        path: 'coffee_material/',
        name: 'coffeeMaterial',
        component: () => import('@/pages/product/category.vue'),
        props: { categoryName: 'コーヒー器具', categorySlug: 'coffee_material' },
        meta: {
          title: 'コーヒー器具',
          hero: {
            title: 'コーヒー器具',
            titleEn: 'COFFEE MATERIAL',
            description: '本格派に定評のある\nKONO特別仕様品\n風味を引き出す必携アイテム',
          },
          breadcrumb: [{ label: 'トップ', path: '/' }],
        },
      },
      {
        path: 'coffee_material/:productId',
        name: 'coffeeMaterialDetail',
        component: () => import('@/pages/product/detail.vue'),
        meta: {
          title: 'コーヒー器具',
          breadcrumb: [
            { label: 'トップ', path: '/' },
            { label: 'コーヒー器具', path: '/coffee_material/' },
          ],
        },
      },
      */
      {
        path: 'news/',
        name: 'News',
        component: () => import('@/pages/news.vue'),
        meta: {
          title: 'お知らせ', // titleタグ用
          hero: {
            title: 'お知らせ',
            titleEn: 'NEWS',
          },
          breadcrumb: [{ label: 'トップ', path: '/' }],
        },
      },
      {
        path: 'company/',
        name: 'Company',
        component: () => import('@/pages/company.vue'),
        meta: {
          title: '会社情報', // titleタグ用
          hero: {
            title: '会社情報',
            titleEn: 'COMPANY',
          },
          breadcrumb: [{ label: 'トップ', path: '/' }],
        },
      },
    ],
  },
  {
    path: '/',
    name: 'SimpleLayout',
    component: () => import('../layouts/simple-layout.vue'),
    children: [
      {
        path: 'privacy_policy/',
        name: 'PrivacyPolicy',
        component: () => import('@/pages/privacy-policy.vue'),
        meta: {
          title: 'プライバシーポリシー', // titleタグ用
          hero: {
            title: 'プライバシーポリシー',
            titleEn: 'PRIVACY POLICY',
          },
          breadcrumb: [{ label: 'トップ', path: '/' }],
        },
      },
      {
        path: 'contact/',
        name: 'ContactIndex',
        component: () => import('@/pages/contact/index.vue'),
        meta: {
          title: 'お問い合わせ', // titleタグ用
          hero: {
            title: 'お問い合わせ',
            titleEn: 'CONTACT',
          },
          breadcrumb: [{ label: 'トップ', path: '/' }],
        },
      },
    ],
  },
]

export default routes

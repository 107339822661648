import { ref, watch, computed } from 'vue'
import type { Ref } from 'vue'
import type { ProductCollection } from '@/models'

export function useProductFiltering(productList: Ref<ProductCollection | null>) {
  /** 選択中のサブカテゴリー */
  const selectedSubCategory = ref<string | null>(null)
  /** 選択中のカテゴリー仕分け */
  const selectedCategoryClass = ref<string | null>(null)

  /** サブカテゴリーで絞り込みされた商品一覧（カテゴリー仕分けによる絞り込みは適用していない） */
  const filteredProductListBySubCategory = computed(() => {
    return selectedSubCategory.value
      ? productList.value?.filterBySubCategory(selectedSubCategory.value) ?? null
      : productList.value
  })

  /** サブカテゴリーとカテゴリー仕分けで絞り込みされた商品一覧（最終的に表示する一覧） */
  const filteredProductList = computed(() => {
    if (!filteredProductListBySubCategory.value) return null
    return selectedCategoryClass.value
      ? filteredProductListBySubCategory.value.filterByClass(selectedCategoryClass.value) ?? null
      : filteredProductListBySubCategory.value
  })

  /** サブカテゴリーのデータ */
  const subCategoryList = computed(() => {
    const subCategories = productList.value?.subCategories?.map(val => ({ label: val, value: val })) ?? []
    return [{ label: '全て', value: null }, ...subCategories]
  })
  /** カテゴリー仕分けのデータ */
  const categoryClassList = computed(() => {
    const categoryClasses =
      filteredProductListBySubCategory.value?.categoryClasses.map(val => ({ label: val, value: val })) ?? []
    return [{ label: '全て', value: null }, ...categoryClasses]
  })

  /** サブカテゴリーが変わったらカテゴリー仕分けをリセット */
  watch(selectedSubCategory, () => {
    selectedCategoryClass.value = null
  })

  return { filteredProductList, selectedSubCategory, selectedCategoryClass, subCategoryList, categoryClassList }
}

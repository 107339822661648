import { createApp } from 'vue'
import VueGtag from 'vue-gtag'
import App from './app.vue'
import router from './router'
import { CommonButton } from '@/components'

import 'normalize.css/normalize.css'
import './css/app.scss'

createApp(App)
  .component('CommonButton', CommonButton)
  .use(router)
  .use(
    VueGtag,
    {
      config: { id: 'G-VYTJMRF9NH' },
    },
    router,
  )
  .mount('#app')

import { ref, onUnmounted } from 'vue'

// TODO: 多重起動してしまうので直したい

export function useMediaQuery() {
  const isDesktop = ref(false)
  const mediaQueryList = window.matchMedia('(min-width: 601px)')

  function listener() {
    isDesktop.value = mediaQueryList.matches
  }

  // NOTE: Safari 14 より前のバージョンでは `addEventListener` ではなく `addListener` を使う必要がある
  if ('addEventListener' in mediaQueryList) {
    mediaQueryList.addEventListener('change', listener)
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    mediaQueryList.addListener(listener)
  }

  listener()

  onUnmounted(() => {
    // NOTE: Safari 14 より前のバージョンでは `removeEventListener` ではなく `removeListener` を使う必要がある
    if ('removeEventListener' in mediaQueryList) {
      mediaQueryList.removeEventListener('change', listener)
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mediaQueryList.removeListener(listener)
    }
  })

  return { isDesktop }
}

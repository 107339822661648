import { reactive, watch, provide, inject, InjectionKey } from 'vue'
import { useRoute } from 'vue-router'

class CommonLayoutMeta {
  title = ''
  breadcrumbList: { label: string; path?: string }[] = []
  bgColor = ''
}

const useCommonLayoutMetaKey: InjectionKey<CommonLayoutMeta> = Symbol('useCommonLayoutMetaKey')

export function useCommonLayoutMetaProvide() {
  const route = useRoute()
  /** CommonLayoutで使用するメタデータ */
  const commonLayoutMeta = reactive<CommonLayoutMeta>(new CommonLayoutMeta())

  provide(useCommonLayoutMetaKey, commonLayoutMeta)

  watch(
    route,
    val => {
      /** ルートが切り替わったらリセット */
      Object.assign(commonLayoutMeta, new CommonLayoutMeta())

      /** タイトルの設定 */
      commonLayoutMeta.title = val.meta.title ?? ''

      /** パンくずリストの設定 */
      if (val.meta?.breadcrumb) {
        commonLayoutMeta.breadcrumbList.push(...val.meta.breadcrumb)
      }
      commonLayoutMeta.breadcrumbList.push({ label: route.meta.title ?? '' })

      /** 背景色の設定 */
      commonLayoutMeta.bgColor = '#f2f2f2'
    },
    { immediate: true, deep: true },
  )

  return commonLayoutMeta
}

export default function useCommonLayoutMeta() {
  const commonLayoutMeta = inject(useCommonLayoutMetaKey) ?? new CommonLayoutMeta()

  return commonLayoutMeta
}
